<template>
  <div class="productSwipe_container">
    <div class="productSwipe_content">
      <div
        class="productSwipe_content_item"
        v-for="(v, i) in products"
        :key="i"
        :productIndex="i"
      >
        <img :src="v.url" />
        <!-- <div class="productSwipe_content_item_info">
          <span class="productSwipe_content_item_info_name">{{
            "晨桥咖啡（" + (v.name || "暂无分店名") + "）"
          }}</span>
          <div class="productSwipe_content_info_adr_icon">
            <img src="@/assets/img/local.png" />
          </div>
          <span class="productSwipe_content_info_adr_txt">{{
            v.adr || "暂无分店地址"
          }}</span>
          <div class="productSwipe_content_info_qr">
            <img src="@/assets/img/store_qr.png" />
          </div>
        </div> -->
      </div>
    </div>

    <ul class="productSwipe_idot">
      <li
        :class="productIndex === v - 1 ? 'idot_active' : 'idot'"
        v-for="v in products.length"
        :key="v"
      ></li>
    </ul>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  props: {
    products: {
      type: Array,
      default: [],
    },
    sec: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      productIndex: 0,
      productTimer: 0,
    };
  },
  methods: {
    refresh() {
      if (this.productIndex < 0) {
        this.productIndex = this.products.length - 1;
      } else if (this.productIndex >= this.products.length - 1) {
        this.productIndex = 0;
      } else {
        this.productIndex++;
      }
      console.log(`productIndex -> ${this.productIndex}`);
      const container = document.getElementsByClassName("productSwipe_container")[0];

      let width = getComputedStyle(container).width;
      //@ts-ignore
      width = width.slice(0, -2);
      //@ts-ignore
      container.querySelector(".productSwipe_content").style.left =
        //@ts-ignore
        this.productIndex * width * -1 + "px";
    },

    init() {
      //   if (this.products.length > 1) {
      this.productTimer = setInterval(() => {
        this.refresh();
      }, this.sec * 1000);
      //   }
    },
  },
  mounted() {
    if (this.products.length > 1) {
      clearInterval(this.productTimer);
      this.init();
    }
  },
  beforeDestroy() {
    clearInterval(this.productTimer);
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/global.scss";

.productSwipe_container {
  width: 100%;
  height: auto;
  overflow: hidden;
  .productSwipe_content {
    width: 100%;
    height: auto;
    @include flex(row, flex-start, center);
    left: 0;
    position: relative;
    transition: all 1s;
    .productSwipe_content_item {
      flex: 0 0 100%;
      height: 100%;
      position: relative;
      img {
        width: 100%;
      }
      .productSwipe_content_item_info {
        position: absolute;
        bottom: 38px;
        color: #fff;
        width: 100%;
        //   left: 50%;
        //   transform: translate(-50%, 0);
        @include flex(row, center, center);
        flex-wrap: nowrap;
        .productSwipe_content_item_info_name {
          margin-right: 59px;
          @include eew(22px);
        }
        .productSwipe_content_info_adr_icon {
          flex: 0 0 17px;
          height: 20px;
          margin-right: 10px;
          img {
            width: 100%;
          }
        }
        .productSwipe_content_info_adr_txt {
          @include pfl(16px);
          margin-right: 78px;
        }
        .productSwipe_content_info_qr {
          flex: 0 0 37px;
          height: 37px;
          img {
            width: 100%;
          }
        }
      }
    }
  }

  .productSwipe_idot {
    width: 100%;
    height: 15px;
    list-style: none;
    margin: 54px 0 0 0;
    padding: 0;
    @include flex(row, center, center);
    .idot,
    .idot_active {
      flex: 0 0 15px;
      height: 15px;
      @include borderRadius(15px);
      margin-right: 20px;
    }
    .idot {
      background: #999;
    }
    .idot_active {
      background: #343434;
    }
  }
}
</style>

<style>
</style>