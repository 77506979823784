export const stores = [
    {
        url: require("@/assets/product/6.png")
    },
    {
        url: require("@/assets/product/7.png")
    },
    {
        url: require("@/assets/product/8.png")
    },
    {
        url: require("@/assets/product/9.png")
    },
    {
        url: require("@/assets/product/10.jpg")
    },

]

export const style = [
    {
        url: require("@/assets/img/s1.png")
    },
    {
        url: require("@/assets/img/s2.png")
    },
    {
        url: require("@/assets/img/s3.png")
    },
    {
        url: require("@/assets/img/s4.png")
    },
    {
        url: require("@/assets/img/s5.png")
    },

]



export const products = [
    {
        url: require("@/assets/product/1.png")
    },
    {
        url: require("@/assets/product/2.png")
    },
    {
        url: require("@/assets/product/3.png")
    },
    {
        url: require("@/assets/product/4.png")
    },
    {
        url: require("@/assets/product/5.png")
    },

]

export const chenqiao = [
   
]

export const zhaohuataiLogo = require("@/assets/img/zht.png")
// export const chenqiaoLogo = require("@/assets/img/cqlogo.png")
// export const chenqiaotext = require("@/assets/img/cqtxt.png")
export const storebanner = require("@/assets/img/banner2.png")
// export const storeBoxLocal = require("@/assets/img/storeBoxLocal.png")
// export const storeBoxQr = require("@/assets/img/storeBoxQr.png")

export const productbanner = require("@/assets/img/banner3.jpg")

// export const chengshi = require("@/assets/img/chengshi.png")
// export const jingying = require("@/assets/img/jingying.png")
export const rcbanner = require("@/assets/img/banner4.png")
// export const wuye = require("@/assets/img/wuye.png")
export const bmqr = require("@/assets/img/bmqr.png")
export const kfqr = require("@/assets/img/kfqr.png")