<template>
  <div class="product_container">
    <div class="product_content_banner">
      <img :src="productbanner" alt="" />
    </div>
    <span class="title">为你推荐</span>
    <span class="title_en">Recommended For You</span>
    <div class="product_content_product">
      <ProductSwipe :products="products" />
    </div>
    <span class="title">产品介绍</span>
    <span class="title_en">Product Introduction</span>
    <div class="product_content_info">
      我们主要使用的是竹木纤维材质，竹木纤维采用竹粉、木粉、碳粉，天然树脂 微晶颗粒技术通过高温高压压缩而成的一种新型环保装饰材料。
      厂家这边也在不断的研发新型产品应用于各大领域，2023迎合市场发展需求，第九代产品 冰火板 金刚板
      全铝板材全国首发上线进入市场（太空舱便捷旅馆和睡眠舱，蜗居等新型便捷住宿城市旅馆是我们的研发项目，城市外来人口越来越多，外来务工人员旅游出差人员入住方便，经济实惠，便民利民是我们主推的发展理念）
    </div>
  </div>
</template>

<script>
import { productbanner, products } from "@/mock/data";
import ProductSwipe from "@/component/productSwipe/index.vue";

export default {
  components: { ProductSwipe },
  methods: {},
  metaInfo: {
    title: '彬茂建材-产品',
    meta: [

      {             // set meta
        name: '彬茂建材,广东彬茂,广东彬茂新型建筑材料有限公司,环保材料,建筑装修,建筑装修,环保建筑装修材料,木饰面,碳晶板,金属线条,装修设计效果图,防火防水装修材料',
        content: '我们主要使用的是竹木纤维材质，竹木纤维采用竹粉、木粉、碳粉，天然树脂 微晶颗粒技术通过高温高压压缩而成的一种新型环保装饰材料。' +
          '厂家这边也在不断的研发新型产品应用于各大领域，2023迎合市场发展需求，第九代产品 冰火板 金刚板' +
          '全铝板材全国首发上线进入市场（太空舱便捷旅馆和睡眠舱，蜗居等新型便捷住宿城市旅馆是我们的研发项目，城市外来人口越来越多，外来务工人员旅游出差人员入住方便，经济实惠，便民利民是我们主推的发展理念）'
      },
    ],
  },
  data() {
    return {
      products: products,
      productbanner: productbanner
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/global.scss";

.product_container {
  width: 100%;
  height: auto;
  @include flex(column, flex-start, center);

  .product_content_banner {
    width: 100%;
    background-color: #333;

    img {
      width: 100%;
    }
  }

  .title {
    @include gew(36px);
    margin-top: 98px;
    color: #343434;
  }

  .title_en {
    @include pfl(20px);
    margin-top: 24px;
    margin-bottom: 49px;
    color: #777777;
  }

  .product_content_product {
    width: 1440px;
    position: relative;
  }

  .product_content_info {
    width: 1440px;
    @include pfl(20px);
    color: #777777;
    line-height: 61px;
    text-indent: 40px;
    letter-spacing: 8px;
  }
}</style>